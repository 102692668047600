#about {
  padding-top: 5rem;
  height: calc(100% - 5rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-content {
  height: 100%;
  width: 100vw;
  max-width: 75rem;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 2rem;
}

.about-title {
  font-size: 2rem;
}

.about-content > p,
.about-content > span {
  text-shadow: 0px 3px black;
}

@media screen and (max-width: 840px) {
  .about-content {
    font-size: 4vw;
    flex-direction: column;
    row-gap: 2rem;
    padding: 0 4rem;
  }
}
