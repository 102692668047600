.navbar {
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: rgb(30, 30, 30);
}

.logo-container {
  position: relative;
  flex-shrink: 1;
}

.logo-container > .logo {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 3rem;
  width: 3rem;
  background-image: url("../../assets/img/shine.png");
  background-repeat: repeat;
  background-size: cover;
  opacity: 0;
  mix-blend-mode: color-dodge;
}

.right-actions {
  flex-shrink: 1;
  display: flex;
}

.overlay:hover {
  opacity: 1;
  animation: moveBackground 0.5s infinite linear; /* Animation */
}

@keyframes moveBackground {
  from {
    background-position-y: 0; /* Initial background position */
  }
  to {
    background-position-y: -100%; /* Move background image upwards */
  }
}

.navbar-menu-list-item {
  margin: 1rem;
  cursor: pointer;
  font-family: "Press Start 2P", system-ui;
  text-shadow: 0px 3px black;
}

.navbar-menu-list-item:hover {
  color: #f9a31b;
  padding-bottom: 0.5rem;
  border-bottom: 4px solid #f9a31b;
}

.ra-narrow {
  display: none;
}

@media screen and (max-width: 840px) {
  .ra-narrow {
    display: block;
  }

  .ra-wide {
    display: none;
  }

  .navbar-menu-horizontal {
    display: none;
    flex-grow: 1;
  }
}
