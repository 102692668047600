#home {
  height: calc(100vh - 5rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
}

.home-content {
  height: 100%;
  width: 100vw;
  max-width: 75rem;
  padding: 2rem;
  font-size: 2rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-content > p,
.home-content > span {
  text-shadow: 0px 3px black;
}

.hello {
  font-size: 1.75rem;
  font-weight: 100;
}

.intro-name {
  color: #f9a31b;
}

.intro-img {
  width: 16rem;
  align-self: center;
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease;
}

.intro-para {
  font-size: medium;
  font-weight: 100;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.speciality {
  opacity: 0;
  transition: opacity 1s ease;
  height: 64pt;
}

.appear {
  opacity: 1;
}

@media screen and (max-width: 840px) {
  .home-content {
    font-size: 8vw;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .hello {
    font-size: 6vw;
  }
  .speciality {
    height: 96pt;
  }
  .intro-img {
    width: 12rem;
    align-self: center;
  }
}
