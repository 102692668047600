#support {
  padding-top: 5rem;
  height: calc(100% - 5rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.support-content {
  height: 100%;
  width: 100vw;
  max-width: 75rem;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;
}

.support-title {
  font-size: 2rem;
}

.support-text span {
  display: inline-block;
  animation: wave 2s infinite ease-in-out;
}

@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.support-text span:nth-of-type(2n) {
  animation-delay: 0s;
}
.support-text span:nth-of-type(2n + 1) {
  animation-delay: 0.1s;
}

@media screen and (max-width: 840px) {
  .support-content {
    margin-top: 1em;
    font-size: 6vw;
    flex-direction: column;
    row-gap: 2rem;
    padding: 0 4rem;
  }
}
