.button-container {
  margin: 4px;
  position: relative;
  display: inline-block;
  padding: 4px;
  background-color: #d9d9d9;
  overflow: hidden;
  background-clip: content-box;
  cursor: pointer;
  user-select: none;
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  width: fit-content;
}

.button-container:active {
  top: 4px;
}

.button-container:hover {
  filter: hue-rotate(180deg);
}

.button-container.success {
  background-color: forestgreen;
  color: black;
}

.button-container.danger {
  background-color: indianred;
  color: gainsboro;
}

.button-container > p {
  margin: 8px;
  font-size: 8pt;
}

.corner {
  position: absolute;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/svg/corner.svg");
  background-size: cover;
}

.corner-top-left {
  top: 0;
  left: 0;
}

.corner-top-right {
  top: 0;
  right: 0;
  transform: scaleX(-1);
}

.corner-bottom-left {
  bottom: 0;
  left: 0;
  transform: scaleY(-1);
}

.corner-bottom-right {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.border {
  position: absolute;
  background-color: #000;
}

.border-top,
.border-bottom {
  left: 8px;
  right: 8px;
  height: 4px;
}

.border-left,
.border-right {
  top: 8px;
  bottom: 8px;
  width: 4px;
}

.border-top {
  top: 0;
}

.border-bottom {
  bottom: 0;
}

.border-left {
  left: 0;
}

.border-right {
  right: 0;
}

@media screen and (max-width: 840px) {
  .button-container > p {
    font-size: 6pt;
  }
}
