.image-container {
  margin: 4px;
  position: relative;
  display: inline-block;
  background-color: #d9d9d9;
  overflow: hidden;
  background-clip: content-box;
  user-select: none;
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  width: fit-content;
  border: solid 4px #ccc;
}

.image-container > img {
  width: 100%;
}

.image-decor {
  position: absolute;
  width: 80px;
  height: 8px;
  background-image: url("../../assets/svg/image-decor.svg");
  background-size: contain;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/img/shine.png");
  background-repeat: repeat;
  background-size: cover;
  opacity: 0;
  mix-blend-mode: color-dodge;
}

.image-overlay:hover {
  opacity: 1;
  animation: moveBackground 0.5s infinite linear; /* Animation */
}

@keyframes moveBackground {
  from {
    background-position-y: 0; /* Initial background position */
  }
  to {
    background-position-y: -100%; /* Move background image upwards */
  }
}
