.footer {
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  z-index: 5;
  background-color: rgb(30, 30, 30);
}

.social-media {
  text-align: center;
  white-space: nowrap;
}

@media screen and (max-width: 840px) {
  .social-media > * > .button-container {
    margin: 0px;
    position: relative;
    display: inline-block;
    padding: 4px;
    background-color: #d9d9d9;
    overflow: hidden;
    background-clip: content-box;
    cursor: pointer;
    user-select: none;
    font-family: "Press Start 2P", system-ui;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    width: fit-content;
  }
}
